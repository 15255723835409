<template>
  <div>
    <v-card
      flat
      dense
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">Loan Data</h4></v-toolbar-title>
      </v-toolbar>
      <v-row>
        <v-col
          cols="12"
          md="12"
          class="pt-6"
          v-if="Object.keys(this.member_to_loan).length ===0 && !is_approved"
        >
          <v-radio-group inline v-model="radios" @change="calculation_of_loan">
            <v-radio label="Is Officer/Committee" value="is_calculator_committee"></v-radio>
            <v-radio label="Is Diminishing" value="is_deminishing"></v-radio>
          </v-radio-group>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="pt-6"
        >
          <v-select
            v-if="can_edit"
            :items="type_of_loan_items"
            item-value="type"
            item-text="type"
            label="Type of Loan"
            required
            :rules="rules.combobox_rule"
            v-model="type_of_loan"
            @change="selected_type_of_loan"
            outlined
            dense
          ></v-select>
          <v-text-field
            v-else
            outlined
            dense
            label="Type of Loan"
            v-model="type_of_loan"
            readonly
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="pt-6"
          v-if="can_edit"
        >
          <v-select
            v-if="type_of_loan==='Laptop'||type_of_loan==='Cellphone'"
            :items="available_gadget_items"
            label="Model"
            item-value="model_raw"
            item-text="model"
            required
            :rules="rules.combobox_rule"
            v-model="type_of_loan_others"
            outlined
            dense
          ></v-select>
          <v-text-field
            v-else
            label="Specify"
            :rules="rules.default_max_100_character_and_no_empty_rule"
            v-model="type_of_loan_others"
            outlined
            dense
          >
          </v-text-field>

        </v-col>
        <v-col
          cols="12"
          md="3"
          class="pt-6"
          v-else
        >
          <v-text-field
            outlined
            dense
            label="Specify"
            v-model="type_of_loan_others"
            readonly
          ></v-text-field>

        </v-col>
        <v-col
          cols="12"
          md="3"
          class="pt-6"
        >
          <v-select
            v-if="can_edit"
            :items="['PDC CHEQUE','ATM','TITLE','OTHERS']"
            item-value="type"
            item-text="type"
            label="Type of Collateral"
            required
            :rules="rules.combobox_rule"
            v-model="type_of_collateral"
            @change="calculation_of_loan"
            outlined
            dense
          ></v-select>
          <v-text-field
            v-else
            outlined
            dense
            label="Type of Collateral"
            v-model="type_of_collateral"
            :rules="rules.default_max_45_character_and_no_empty_rule"
            @keyup="calculation_of_loan"
            @change="calculation_of_loan"
            readonly
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
          class="pt-6"
        >
          <v-text-field
            v-if="type_of_collateral==='OTHERS' && can_edit"
            outlined
            dense
            label="Specify Collateral"
            v-model="specify_collateral"
            :rules="rules.default_max_45_character_and_no_empty_rule"
            @keyup="calculation_of_loan"
          ></v-text-field>
          <v-text-field
            v-if="type_of_collateral==='OTHERS' && !can_edit"
            outlined
            dense
            label="Specify Collateral"
            v-model="specify_collateral"
            readonly
          ></v-text-field>
        </v-col>
        <v-row cols="12"
               md="3"
               v-if="type_of_loan === 'Business'">
          <v-avatar
            rounded
            size="40"
            class="me-6"
          >
            <v-img :src="cropped_bus"></v-img>
          </v-avatar>
          <v-btn
            color="primary"
            @click="$refs.refInputEl_bus.click()"
          >
            <v-icon class="d-sm-none">
              {{ icons.mdiCloudUploadOutline }}
            </v-icon>
            <span class="d-none d-sm-block">Upload Permit</span>
          </v-btn>
          <input
            ref="refInputEl_bus"
            type="file"
            accept=".jpeg,.png,.jpg"
            :hidden="true"
            v-on:click="$refs.refInputEl_bus.value = ''"
            v-on:change="croppie_bus"
          />
        </v-row>
        <v-col
          cols="12"
          md="3"
          v-else
        ></v-col>
        <v-col
          cols="12"
          md="3"
          class="pt-6"
        >
          <v-select
            v-if="can_edit"
            :items="['Micro Finance Loan','Education', 'Emergency/Medical', 'House Construction & Repairs', 'Utilities Services', 'Commodity/Appliance', 'Others Providential', 'Agricultural', 'Trading','Manufactoring (Non Agri)','Service','Others Productive']"
            label="Purpose of Loan"
            required
            :rules="rules.combobox_rule"
            v-model="purpose_of_loan"
            @change="calculation_of_loan"
            outlined
            dense
          ></v-select>
          <v-text-field
            v-else
            outlined
            dense
            label="Purpose of Loan"
            v-model="purpose_of_loan"
            readonly
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Loan Amount"
                        v-if="can_edit"
                        type="number"
                        min="1"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        v-model="loan_amount"
                        @keyup="calculation_of_loan"
                        @change="calculation_of_loan"
                        outlined
                        dense
          >
          </v-text-field>
          <v-text-field
            v-else
            outlined
            dense
            label="Loan Amount"
            v-model="loan_amount"
            readonly
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-if="can_edit"
            :items="['Weekly', '15/30', 'Monthly']"
            label="Mode of Loan"
            required
            :rules="rules.combobox_rule"
            v-model="mode_of_loan"
            @change="calculation_of_loan"
            outlined
            dense
          ></v-select>
          <v-text-field
            v-else
            outlined
            dense
            label="Mode of Loan"
            v-model="mode_of_loan"
            readonly
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-if="can_edit"
            :items="['1 month/s', '2 month/s', '3 month/s', '4 month/s', '5 month/s', '6 month/s', '7 month/s', '8 month/s', '9 month/s', '10 month/s', '11 month/s', '12 month/s', '18 month/s',  '24 month/s'
                                                            ,'36 month/s','48 month/s','60 month/s',]"
            label="Terms of Loan"
            required
            :rules="rules.combobox_rule"
            v-model="terms"
            @change="calculation_of_loan"
            outlined
            dense
          ></v-select>
          <v-text-field
            v-else
            outlined
            dense
            label="Terms of Loan"
            v-model="terms"
            readonly
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Due Amount"
                        readonly
                        v-model="due_amount"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Interest"
                        readonly
                        v-model="interest"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Loan Savings"
                        readonly
                        v-model="loan_savings"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="MISC"
                        readonly
                        v-model="misc"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Total"
                        readonly
                        v-model="total"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-select
            v-if="Object.keys(this.value).length===0"
            :items="ci_items"
            item-value="id"
            item-text="name"
            label="CI By"
            required
            :rules="rules.combobox_rule"
            v-model="ci_id"
            outlined
            dense
            @change="calculation_of_loan"
          ></v-select>
          <v-text-field label="CI By"
                        v-else
                        readonly
                        v-model="ci_name"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Loan Insurance"
                        readonly
                        v-model="loan_insurance"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Loan Processing Fee"
                        readonly
                        v-model="loan_processing_fee"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Mortuary Insurance"
                        readonly
                        v-model="mortuary_insurance"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Foundation"
                        readonly
                        v-model="foundation"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="NMAPI"
                        readonly
                        v-model="nmapi"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Share Capital"
                        type="number"
                        min="0"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        v-model="share_capital"
                        @keyup="calculation_of_loan"
                        @change="calculation_of_loan"
                        outlined
                        dense
                        v-if="can_edit"
          >
          </v-text-field>
          <v-text-field
            v-else
            outlined
            dense
            label="Share Capital"
            v-model="share_capital"
            readonly
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Regular Savings"
                        type="number"
                        min="0"
                        :rules="rules.default_max_45_character_and_no_empty_rule"
                        v-model="regular_savings"
                        @keyup="calculation_of_loan"
                        @change="calculation_of_loan"
                        outlined
                        dense
                        v-if="can_edit"
          >
          </v-text-field>
          <v-text-field
            v-else
            outlined
            dense
            label="Regular Savings"
            v-model="regular_savings"
            readonly
          ></v-text-field>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-text-field label="Total Net"
                        readonly
                        v-model="total_net"
                        outlined
                        dense
          >
          </v-text-field>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>
<script>

  import {mapActions, mapGetters} from "vuex";
  import moment from "moment";
  import {mdiAlertOutline, mdiCloudUploadOutline} from '@mdi/js'

  export default {
    props: {
      is_approved: Boolean,
      value: Object,
      member_to_loan: Object,
      can_edit: Boolean,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
        },
      }
    },
    data() {
      return {
        type_of_loan_items: [],
        interest_per_month: [],
        premium_per_month: [],
        loan_insurance: 0,
        loan_insurance_comm: '0',
        loan_insurance_addtnl: '0',
        loan_processing_fee: '0',
        mortuary_insurance: '250',
        share_capital: '0',
        nmapi: '0',
        foundation: '100',
        regular_savings: '0',
        misc: '0',
        radios: '',

        saving: false,
        alert: false,
        alert_message: '',
        croppieImage: '',
        cropped: this.avatarImg,
        croppieImage_bus: '',
        cropped_bus: this.avatarImg,
        croppieImage_home: '',
        cropped_home: this.avatarImg,

        search: '',
        search_member: '',
        search_items: [],
        available_gadget_items: [],

        search_co: '',
        search_member_co: '',
        search_items_co: [],

        collectors_items: [],
        collector_id: 0,

        date_accomplished: '',
        laf_no: '',
        last_name: '',
        first_name: '',
        middle_name: '',
        date_of_birth: '',
        place_of_birth: '',
        height: '',
        weight: '',
        tin_no: '',
        sss_no: '',
        type_of_id: '',
        id_no: '',
        civil_status: '',
        no_of_dependents: '',
        gender: '',
        present_address: '',
        residence_is: '',
        residence_specify: '',
        permanent_address: '',
        religion: '',
        citizenship: '',
        contact_no: '',
        email_address: '',
        occupation: '',
        employment_date: '',
        employer: '',
        employer_address: '',
        employer_contact_no: '',
        monthly_income: '',
        employment_status: '',
        family_last_name: '',
        family_first_name: '',
        family_middle_name: '',
        family_date_of_birth: '',
        family_contact_no: '',
        family_occupation: '',
        family_employer: '',
        family_employer_address: '',
        family_monthly_income: '',
        family_employment_status: '',
        type_of_collateral: '',
        family_employment_date: '',
        specify_collateral: '',
        is_with_signature: true,
        is_calculator_committee: false,
        is_deminishing: false,

        purpose_of_loan: '',
        type_of_loan: '',
        type_of_loan_others: '',
        loan_amount: '',
        mode_of_loan: '',
        terms: '',
        due_amount: '',
        interest: '',
        interestt: 0,
        due_interest: 0,
        loan_savings: '',
        loan_savingss: '',
        total: '',
        ci_name: '',
        totall: '',
        ci_items: [],
        ci_id: 0,
        amount_due: 0,
        due_loan_savings: '',

        last_name_co: '',
        first_name_co: '',
        middle_name_co: '',
        permanent_address_co: '',
        total_net: '0',

      }
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id']),
    },
    async mounted() {
      this.date_accomplished = moment().format('YYYY-MM-DD')
      await this.type_of_loan_list()
        .then(response => {
          this.type_of_loan_items = response.data
        })
      if (Object.keys(this.value).length > 0) {
        this.collector_id = this.value.collector_id
        this.last_name = this.value.members.last_name
        this.first_name = this.value.members.first_name
        this.middle_name = this.value.members.middle_name
        this.type_of_loan = this.value.type_of_loan
        this.loan_amount = this.value.amount
        this.type_of_loan_others = this.value.specify_type_of_loan
        this.mode_of_loan = this.value.mode_of_loan
        this.terms = this.value.terms + ' month/s'
        this.type_of_collateral = this.value.type_of_collateral
        this.specify_collateral = this.value.specify_collateral
        this.purpose_of_loan = this.value.purpose_of_loan
        this.laf_no = this.value.laf_no
        this.share_capital = this.value.share_capital
        this.regular_savings = this.value.regular_savings
        this.loan_savingss = this.value.regular_savings
        this.ci_name = this.value.ci_by.last_name + ', ' + this.value.ci_by.first_name
        this.ci_id = this.value.ci_by.id
        // this.date_accomplished = moment(this.value.date_accomplished, 'MMMM D, YYYY').format('YYYY-MM-DD')

        if (this.value.co_maker != null) {
          this.last_name_co = this.value.co_maker.last_name
          this.first_name_co = this.value.co_maker.first_name
          this.middle_name_co = this.value.co_maker.middle_name
          this.permanent_address_co = this.value.co_maker.permanent_address
        }
        await this.calculation_of_loan()
      } else {
        await this.loan_initialize_data({
          branch_id: this.branch_id,
        })
          .then(response => {
            this.laf_no = 'L-' + moment().year() + '-' + (response.data[0].counter + 1)
            this.ci_items = response.data[0].ci_data
            this.collectors_items = response.data[0].collectors_data
          })
          .catch(error => {
            this.saving = false
          })
      }
      await this.calculation_of_loan()
    },
    methods: {
      ...mapActions('loans_data', ['type_of_loan_list', 'loan_initialize_data']),
      ...mapActions('inventory', ['available_inventory',]),
      croppie(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped = this.croppieImage = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      async croppie_bus(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_bus = this.croppieImage_bus = e.target.result
          this.$emit('business_permit', {
            croppieImage_bus: this.croppieImage_bus,
          })
        };
        reader.readAsDataURL(files[0]);
      },
      croppie_home(e) {
        var files = e.target.files || e.dataTransfer.files;
        if (!files.length) return;

        var reader = new FileReader();
        reader.onload = e => {
          this.cropped_home = this.croppieImage_home = e.target.result
        };

        reader.readAsDataURL(files[0]);
      },
      resett() {
        this.$refs.refInputEl.value = ''
        this.cropped = this.avatarImg

        this.$refs.refInputEl_bus.value = ''
        this.cropped_bus = this.avatarImg

        this.$refs.refInputEl_home.value = ''
        this.cropped_home = this.avatarImg
      },
      formatPrice(value) {
        let val = (value / 1).toFixed(2).replace(',', '.')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      },
      selected_type_of_loan() {
        this.available_gadget_items = []
        if (this.type_of_loan === 'Laptop' || this.type_of_loan === 'Cellphone') {
          this.available_inventory({branch_id: this.branch_id, particulars: this.type_of_loan})
            .then(response => {
              this.available_gadget_items = response.data
            })
            .catch(error => {
              console.log(error)
            })
        }
        this.calculation_of_loan()
      },
      calculation_of_loan() {
        if (this.radios === 'is_calculator_committee') {
          this.is_calculator_committee = true
          this.is_deminishing = false
        }
        if (this.radios === 'is_deminishing') {
          this.is_deminishing = true
          this.is_calculator_committee = false
        }
        var terms = 0
        var interest = 0
        if (this.terms != 0) {
          var split_terms = this.terms.split(' ')
          terms = split_terms[0]
        }
        var savings_percentage = 0.05
        var interest_percentage = 0.028
        var loan_processing_percent = 0.05
        this.mortuary_insurance = '250'
        this.foundation = '100'
        // if (moment(this.date_accomplished, 'YYYY-MM-DD') > moment('2023-06-25', 'YYYY-MM-DD')) {
        //   this.foundation = '100'
        // }

        if (Object.keys(this.member_to_loan).length > 0) {
          if (this.member_to_loan.organization_history.length > 0) {
            interest_percentage = 0.01
            loan_processing_percent = 0.02
          }
        } else {
          interest_percentage = 0.028
          if (this.is_calculator_committee) {
            interest_percentage = 0.01
            loan_processing_percent = 0.02
          }
        }
        var addition_misc = 0
        if (this.type_of_loan === 'Damayan') {
          interest_percentage = 0.0175
          this.mortuary_insurance = '0'
          this.foundation = '0'
          addition_misc = (this.loan_amount * 0.11)
        }
        //Flat Rate
        if (this.type_of_loan === 'Government') {
          if (parseInt(terms) <= 12) {
            interest_percentage = 0.025
          } else if (parseInt(terms) === 24) {
            interest_percentage = 0.02
          } else if (parseInt(terms) === 36) {
            interest_percentage = 0.0175
          } else if (parseInt(terms) === 48) {
            interest_percentage = 0.015
          } else if (parseInt(terms) === 60) {
            interest_percentage = 0.0125
          }
        }
        //End Flat Rate
        interest = (this.loan_amount * interest_percentage) * terms
        if (Object.keys(this.member_to_loan).length > 0) {
          if (this.member_to_loan.organization_history.length === 0 && (this.member_to_loan.member_status === 'Regular Member' || this.member_to_loan.member_status === 'Associate Member')) {
            this.is_deminishing = true
          }
        }
        if (this.is_deminishing) {
          interest = 0;
          //Deminishing
          var int_per_mode = interest_percentage
          var interest_arr = []
          var premium_arr = []
          var tot_ter = 0
          switch (this.mode_of_loan) {
            case "Weekly":
              tot_ter = terms * 4
              int_per_mode = interest_percentage / 4
              break
            case "15/30":
              tot_ter = terms * 2
              int_per_mode = interest_percentage / 2
              break
            case "Monthly":
              tot_ter = terms
              int_per_mode = interest_percentage
              break
          }
          var principal_due = parseFloat((this.loan_amount / tot_ter)).toFixed(2)
          if (principal_due > 0) {
            var balanceee_due = parseFloat(this.loan_amount).toFixed(2)

            for (var x = 1; x <= tot_ter; x++) {
              interest += parseFloat(balanceee_due).toFixed(2) * int_per_mode
              var int_per_month = (parseFloat(balanceee_due).toFixed(2) * int_per_mode)
              interest_arr.push(parseFloat(int_per_month).toFixed(2))
              premium_arr.push(parseFloat(parseFloat(principal_due) + parseFloat(int_per_month)).toFixed(2))
              balanceee_due -= parseFloat(principal_due).toFixed(2)
            }
          }
          this.interest_per_month = interest_arr
          this.premium_per_month = premium_arr
          //EndDeminishing
        }

        this.misc = addition_misc + ''
        this.nmapi = 0
        if (this.type_of_loan === 'Tie-Up') {
          this.nmapi = (this.loan_amount * 0.02) + ''
        }

        this.loan_processing_fee = (this.loan_amount * loan_processing_percent)

        var savings = (this.loan_amount * savings_percentage)

        var term_loan_insurance = terms
        if (term_loan_insurance <= 12) {
          term_loan_insurance = 12;
        }
        var total_to_compute_loan_insurance = parseFloat(this.loan_amount) + (interest);
        var provider = ((total_to_compute_loan_insurance * 1.1) * term_loan_insurance) / 1000;
        var company = ((total_to_compute_loan_insurance * 1.1) * term_loan_insurance) / 1000;
        var commission = (provider * 0.2);
        this.loan_insurance_comm = parseFloat(commission)
        if (company < 300) {
          company = 300
        }
        this.loan_insurance = company;

        this.loan_savings = this.formatPrice(savings)

        this.interest = interest
        this.interestt = interest
        this.loan_savings = savings
        this.loan_savingss = savings
        this.due_loan_savings = savings.toString()
        this.due_interest = interest
        this.total = parseFloat(this.loan_amount) + (interest + savings) + parseFloat(addition_misc)
        this.totall = this.total

        var due_date = moment(this.date_accomplished, 'YYYY-MM-DD').format('MMMM D, YYYY')
        switch (this.mode_of_loan) {
          case "Weekly":
            this.amount_due = ((this.total / terms) / 4)
            due_date = moment(this.date_accomplished, 'YYYY-MM-DD').add(1, "week").format('MMMM D, YYYY')
            this.due_loan_savings = ((savings / terms) / 4).toString()
            this.due_interest = ((interest / terms) / 4).toString()
            break
          case "15/30":
            this.amount_due = ((this.total / terms) / 2)
            due_date = moment(this.date_accomplished, 'YYYY-MM-DD').add(15, "day").format('MMMM D, YYYY')
            this.due_loan_savings = ((savings / terms) / 2).toString()
            this.due_interest = ((interest / terms) / 2).toString()
            break
          case "Monthly":
            this.amount_due = ((this.total / terms))
            due_date = moment(this.date_accomplished, 'YYYY-MM-DD').add(1, "months").format('MMMM D, YYYY')
            this.due_loan_savings = (savings / terms).toString()
            this.due_interest = (interest / terms).toString()
            break
        }
        this.due_date = due_date
        this.due_amount = this.formatPrice(this.amount_due)
        this.interest = this.formatPrice(interest)
        this.total = this.formatPrice(this.total)
        this.loan_savings = this.formatPrice(savings)
        var net_proceeds = (parseFloat(this.loan_amount)) - (parseFloat(this.loan_insurance) + parseFloat(this.loan_processing_fee) + parseFloat(this.mortuary_insurance) + parseFloat(this.share_capital) + parseFloat(this.regular_savings) + parseFloat(this.foundation) + parseFloat(this.nmapi))
        this.total_net = this.formatPrice(net_proceeds)

        this.$emit('data', {
          collector_id: this.collector_id,
          type_of_loan: this.type_of_loan,
          type_of_loan_others: this.type_of_loan_others,
          cropped_bus: this.cropped_bus,
          purpose_of_loan: this.purpose_of_loan,
          loan_amount: this.loan_amount,
          mode_of_loan: this.mode_of_loan,
          terms: this.terms,
          due_amount: this.due_amount,
          interest: this.interest,
          interest_per_month: this.interest_per_month,
          premium_per_month: this.premium_per_month,
          loan_savings: this.loan_savings,
          total: this.total,
          ci_id: this.ci_id,
          loan_insurance: this.loan_insurance,
          loan_processing_fee: this.loan_processing_fee,
          mortuary_insurance: this.mortuary_insurance,
          foundation: this.foundation,
          nmapi: this.nmapi,
          share_capital: this.share_capital,
          regular_savings: this.regular_savings,
          total_net: this.total_net,
          laf_no: this.laf_no,
          due_date: this.due_date,
          totall: this.totall,
          interestt: this.interestt,
          loan_savingss: this.loan_savingss,
          loan_insurance_comm: this.loan_insurance_comm,
          due_interest: this.due_interest,
          due_loan_savings: this.due_loan_savings,
          amount_due: this.amount_due,
          misc: this.misc,
          is_deminishing: this.is_deminishing,
          type_of_collateral: this.type_of_collateral,
          specify_collateral: this.specify_collateral != null ? this.specify_collateral.toUpperCase() : '',
        })
      },
    },
  }
</script>
